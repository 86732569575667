var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "billing.create",
              expression: "'billing.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "billing",
          order_column: "id",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("plan.name"))),
                ]),
                _c(
                  "vs-th",
                  { key: "account_id", attrs: { "sort-key": "account_id" } },
                  [_vm._v(_vm._s(_vm.$t("billing.account_id")))]
                ),
                _c(
                  "vs-th",
                  {
                    key: "account_name",
                    attrs: { "sort-key": "account_name" },
                  },
                  [_vm._v(_vm._s(_vm.$t("billing.account")))]
                ),
                _c(
                  "vs-th",
                  {
                    key: "payment_value",
                    attrs: { "sort-key": "payment_value" },
                  },
                  [_vm._v(_vm._s(_vm.$t("billing.value")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "25%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "account_id", attrs: { width: "10%" } },
                      [_c("div", [_vm._v(" " + _vm._s(tr.account_id))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "account_name", attrs: { width: "25%" } },
                      [
                        _c(
                          "div",
                          [
                            tr.is_institution
                              ? _c("vs-icon", {
                                  attrs: {
                                    "icon-pack": "material-icons",
                                    icon: "account_balance",
                                  },
                                })
                              : _vm._e(),
                            !tr.is_institution
                              ? _c("vs-icon", {
                                  attrs: {
                                    "icon-pack": "material-icons",
                                    icon: "person",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(tr.account_name) + " "),
                            _c("br"),
                            _c("span", { staticClass: "text-sm font-small" }, [
                              _vm._v(
                                " " + _vm._s(_vm._.get(tr, "account.email"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-td",
                      { key: "payment_value", attrs: { width: "15%" } },
                      [
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm._.get(tr, "payment_value_fmt"))
                          ),
                        ]),
                      ]
                    ),
                    _c("vs-td", { attrs: { width: "15%" } }, [
                      _c(
                        "span",
                        { staticClass: "flex" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "billing.edit",
                                expression: "'billing.edit'",
                              },
                            ],
                            attrs: {
                              icon: "EditIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                            },
                            on: {
                              click: function ($event) {
                                return props.edit(props.data[indextr].id)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "billing.delete",
                                expression: "'billing.delete'",
                              },
                            ],
                            attrs: {
                              icon: "Trash2Icon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                            },
                            on: {
                              click: function ($event) {
                                return props.destroy(props.data[indextr])
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }