<template>
  <div>
    <div
      v-permission="'billing.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="billing"
      order_column="id">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('plan.name') }}</vs-th>
        <vs-th key="account_id" sort-key="account_id">{{ $t('billing.account_id') }}</vs-th>
        <vs-th key="account_name" sort-key="account_name">{{ $t('billing.account') }}</vs-th>
        <vs-th key="payment_value" sort-key="payment_value">{{ $t('billing.value') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
           <vs-td width="25%" key="name">
            <div> {{ tr.name }}</div>
          </vs-td>
          <vs-td width="10%" key="account_id">
            <div> {{ tr.account_id }}</div>
          </vs-td>
          <vs-td width="25%" key="account_name">
            <div>
              <vs-icon icon-pack="material-icons" icon="account_balance" v-if="tr.is_institution"/>
              <vs-icon icon-pack="material-icons" icon="person" v-if="!tr.is_institution"/>
              {{ tr.account_name }} <br>
              <span class="text-sm font-small"> {{_.get(tr, 'account.email') }}</span>
            </div>
          </vs-td>
          <vs-td width="15%" key="payment_value">
            <div> {{_.get(tr, 'payment_value_fmt') }}</div>
          </vs-td>
          <vs-td width="15%">
            <span class="flex">
              <feather-icon
                v-permission="'billing.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="props.edit(props.data[indextr].id)"/>
              <feather-icon
                v-permission="'billing.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="props.destroy(props.data[indextr])"/>
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import PlanBillingService from '@/services/api/PlanBillingService'

export default {
  directives: {

  },
  components: {

  },
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/billing/create')
    },
    planEdit(id){
      this.$router.push(`/billing/${id}/edit`)
    },
  },
  beforeMount() {
    this.service = PlanBillingService.build(this.$vs)
  }
}
</script>
